import React from "react"
import Helmet from 'react-helmet'
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Heading from "../../components/heading/heading";
import ContentBlock from "../../components/contentBlock/contentBlock";
import ScrollAnimation from 'react-animate-on-scroll';
import Img from "gatsby-image";

const RetailPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Retail" />
      <Helmet bodyAttributes={{class: 'overflow-x-hidden'}} />

      <ScrollAnimation className="product-page-header mt-5" animateIn="fadeInUp" animateOnce={true}>
        <Heading
          title="Retail"
          copy="The RetailStack Commerce Platform for Retail Stores provides all types of multi-store retailers a single platform for traditional and digital commerce. Our platform was designed to serve as a single source of truth for reporting, offer a unified view of customers and transactions, and also offer flexibility for API integrations with existing first and third party solutions."
          align="center"
        />
        <Img fluid={data.file.childImageSharp.fluid} alt="retail header" />
      </ScrollAnimation>
      <ContentBlock
        imageCol="right"
        title="Modern Retail"
        copy="The RetailStack Commerce Platform offers a unified view of commerce across both traditional and digital channels. Our platform offers a traditional in-store point of sale system, while incorporating the digital platform to support a unified view of your customers and their purchases. With this unified view, you can connect customers online and instore sales into a single view to better understand who your customers are, their buying preferences and what motivates their purchase behavior."
        imageName="retail-modern-retail.png"
      />
      <ContentBlock
        imageCol="left"
        title="Open Platform"
        copy="Our open platform is built in a way that not only solves your current integration needs, but allows you to more rapidly adopt changes in technology and more easily pivot with changes in the business climate. In times where the make-up of the retail landscape is fast evolving, you can more quickly meet your customers where they prefer to shop."
        imageName="retail-open-platform.png"
      />
      <ContentBlock
        imageCol="right"
        title="Unified View"
        copy="Viewing customers and their transactions has never been easier! Traditional point of sale systems focus only on the transaction. The RetailStack platform offers a unified view of both customers and their transactions, which allows for personalized transaction experience for your customers. This view will give you an exact idea who your customers are, their preferences and what motivates their purchase behaviors."
        imageName="retail-unified-features.png"
      />
      <ContentBlock
        imageCol="left"
        title="Core Platform"
        copy="No longer will you need different platforms for in store, online transactions, and third-party marketplace transactions. We offer a single core platform that will handle all of your transaction needs across the entire enterprise. Our unified system will save your time and money and reduce employee onboarding time."
        imageName="retail-core-platform.png"
      />
      <ContentBlock
        imageCol="right"
        title="Retail Integrations"
        copy="We have reimagined how a modern retail enterprise should operate, with a flexible, open platform backed with native cloud capabilities. The options for first- and third-party integrations are endless. One of these options include a smarter way to manage your enterprise, our Enterprise Back Office platform. The platform also supports a wide variety of both hardware and software integrations such as customer-facing displays, fully configurable receipts, store analytics, and marketing automation integrations."
        imageName="retail-integrations.png"
        link="/industries/enterprise"
      />
    </Layout>
  )
}

export default RetailPage

export const query = graphql`
  query {
    file(relativePath: { eq: "retail-pos-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1170) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`